export enum MEDIA_SIZES {
  EXTRA_LARGE = 1200,
  LARGE = 992,
  MEDIUM = 768,
}

export enum FONT_SIZES {
  SMALL = 16,
  MEDIUM = 18,
  LARGE = 20,
  EXTRA_LARGE = 22,
}

export enum THEMES {
  LIGHT = "light",
}

const colors = {
  white: "#FFFFFF",
  black: "#000000",

  light: "#afbda8",
  dark: "#000000",

  attention: "#DD4444",
}

export const light = {
  primary: colors.dark,
  primaryAlt: colors.black,
  inverse: colors.white,
  inverseAlt: colors.light,
  error: colors.attention,
}

export const theme = {
  space: 4,
  fontSize: FONT_SIZES,
}

export const DEFAULT_THEME = {
  themeName: THEMES.LIGHT,
  colors: light,
  ...theme,
}
