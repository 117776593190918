import styled from "./styled"

const Link = styled("a")`
  min-width: 48px;
  min-height: 48px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.primary};
  padding: ${({ theme }) => theme.space * 2}px;
  margin: ${({ theme }) => theme.space}px;
  &:hover {
    color: ${({ theme }) => theme.colors.primaryAlt};
  }
`

export default Link
