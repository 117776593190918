import styled, { CreateStyled } from "@emotion/styled"
import { light, FONT_SIZES } from "../styles"

type Theme = {
  themeName: string
  space: number
  colors: typeof light
  fontSize: typeof FONT_SIZES
}

export default styled as CreateStyled<Theme>
