import React, { useRef, MutableRefObject } from "react"
import {
  WebGLRenderer,
  IcosahedronBufferGeometry,
  MeshPhongMaterial,
  MeshLambertMaterial,
  Color,
  Mesh,
} from "three"
import { Canvas, useRender } from "react-three-fiber"
import useWebGLAvailable from "../hooks/useWebGLAvailable"
import styled from "./styled"
import { DEFAULT_THEME } from "../styles"

const StyledContainer = styled("div")`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledCanvas = styled(Canvas)`
  max-height: 1200px;
  max-width: 1200px;
  min-height: 600px;
`

const Skelet = () => {
  const skelet: MutableRefObject<Mesh | undefined> = useRef()
  useRender(
    () => {
      if (skelet.current) {
        skelet.current.rotation.x += 0.001
        skelet.current.rotation.y += 0.002
      }
    },
    false,
    [skelet]
  )

  return (
    <mesh
      ref={skelet}
      geometry={new IcosahedronBufferGeometry(3, 1)}
      material={
        new MeshLambertMaterial({
          color: 0xe8e3f9,
          wireframe: true,
          skinning: true,
        })
      }
    />
  )
}

const Circle = React.forwardRef((props, ref: any) => {
  useRender(
    () => {
      if (ref.current) {
        ref.current.rotation.x -= 0.002
        ref.current.rotation.y -= 0.003
      }
    },
    false,
    [ref]
  )

  return (
    <mesh
      ref={ref}
      geometry={new IcosahedronBufferGeometry(1.8, 1)}
      material={
        new MeshPhongMaterial({
          color: 0xffffff,
          flatShading: true,
        })
      }
    />
  )
})

export default function Container() {
  const isWebGLAvailable = useWebGLAvailable();
  const circleRef: MutableRefObject<Mesh | undefined> = useRef()

  const handleMouseMove = () => {
    if (circleRef.current) {
      circleRef.current.rotation.y -= 0.01
      circleRef.current.rotation.x -= 0.01
    }
  }

  return isWebGLAvailable ? (
    <StyledContainer
      onMouseMove={handleMouseMove}
      onTouchMove={handleMouseMove}
    >
      <StyledCanvas
        pixelRatio={window.devicePixelRatio}
        gl={new WebGLRenderer({ alpha: false, depth: false })}
        camera={{ fov: 95 }}
      >
        <ambientLight color={new Color(DEFAULT_THEME.colors.inverseAlt)} />
        <directionalLight
          color={new Color(DEFAULT_THEME.colors.inverseAlt)}
          intensity={0.5}
          position={[-0.5, 1, 0]}
        />
        <directionalLight
          color={new Color(DEFAULT_THEME.colors.primary)}
          intensity={0.8}
          position={[0.7, -1, 1]}
        />
        <directionalLight
          color={new Color(DEFAULT_THEME.colors.inverseAlt)}
          intensity={0.3}
          position={[-1, -1, 0.1]}
        />
        <Skelet />
        <Circle ref={circleRef} />
      </StyledCanvas>
    </StyledContainer>
  ) : null
}
