import React, { useEffect, useState } from "react"
import styled from "./styled"

const StyledWord = styled("h1")`
  white-space: nowrap;
  font-size: 1.4em;
  font-weight: 700;
  position: relative;
  padding: 0;
  margin: 0;
  &:after {
    background: ${({ theme }) => theme.colors.primary};
    animation: blink 0.7s steps(1) infinite;
    position: absolute;
    content: "";
    height: 100%;
    width: 0.2rem;
    right: -0.4rem;
  }
  @keyframes blink {
    50% {
      background: transparent;
    }
  }
`

interface Props {
  word: string
}

let timeout: NodeJS.Timeout
const Typer = ({ word, ...props }: Props) => {
  const [currentWord, setCurrentWord] = useState(word.substr(0, 1))

  const recursion = (typing: string, lenght: number) => {
    clearTimeout(timeout)
    if (lenght <= typing.length) {
      setCurrentWord(typing.substr(0, lenght))
      timeout = setTimeout(() => recursion(typing, ++lenght), 50)
    }
  }

  useEffect(() => {
    recursion(word, 1)
    return () => {
      clearTimeout(timeout)
    }
  }, [word])

  return <StyledWord {...props}>{currentWord}</StyledWord>
}

export default Typer
