import React from "react"
import {
  TiSocialGithubCircular,
  TiSocialLinkedinCircular,
  TiSocialAtCircular,
  TiSocialInstagramCircular,
} from "react-icons/ti"
import { TYPER_WORD, ICON_SIZE } from "../constants"
import styled from "../components/styled"
import { Layout, Typer, Link } from "../components"

const StyledContainer = styled("div")`
  z-index: 10;
`

const StyledLink = styled(Link)`
  font-size: 1.6em;
`

const LinkContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

const StyledSiteLink = styled(Link)`
  @media (max-width: 490px) {
   min-width: 160px;
  }
`

const IndexPage = () => (
  <Layout>
    <StyledContainer>
      <StyledLink
        href="mailto:a.garnizone+info@gmail.com"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Typer word={TYPER_WORD} />
      </StyledLink>
      <LinkContainer>
        <StyledSiteLink
          href="https://linkedin.com/in/agrita-garnizone"
          rel="noopener noreferrer"
          target="_blank"
        >
          <TiSocialLinkedinCircular
            style={{ marginRight: "6px" }}
            size={ICON_SIZE}
            title="linkedin"
          />
          LinkedIn
        </StyledSiteLink>

        <StyledSiteLink
          href="mailto:a.garnizone+info@gmail.com"
          rel="noopener"
          target="_blank"
        >
          <TiSocialAtCircular
            style={{ marginRight: "6px" }}
            size={ICON_SIZE}
            title="email"
          />
          E-mail
        </StyledSiteLink>

        <StyledSiteLink
          href="https://github.com/neagrita"
          rel="noopener noreferrer"
          target="_blank"
        >
          <TiSocialGithubCircular
            style={{ marginRight: "6px" }}
            size={ICON_SIZE}
            title="github"
          />
          GitHub
        </StyledSiteLink>
        <StyledSiteLink
          href="https://www.instagram.com/_agrita/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <TiSocialInstagramCircular
            style={{ marginRight: "6px" }}
            size={ICON_SIZE}
            title="instagram"
          />
          Instagram
        </StyledSiteLink>
      </LinkContainer>
    </StyledContainer>
  </Layout>
)

export default IndexPage
