import FontFaceObserver from "fontfaceobserver"

export default function useFontLoaded(
  fonts: string,
  callback: (loaded: boolean) => void
) {
  const font = new FontFaceObserver(fonts)
  font
    .load()
    .then(() => callback(true))
    .catch(() => callback(false))
}
