import React, { useState, useEffect } from "react"
import { ThemeProvider } from "emotion-theming"
import { Global, css } from "@emotion/core"
import styled from "./styled"
import { Background, SEO } from "./"
import { useFontLoaded } from "../hooks"
import { DEFAULT_THEME, FONT_SIZES, MEDIA_SIZES } from "../styles"
import "typeface-roboto/index.css"
import "../styles.css"

const globalStyles = css`
  * {
    font-size: ${FONT_SIZES.SMALL}px;

    @media (min-width: ${MEDIA_SIZES.MEDIUM}px) {
      font-size: ${FONT_SIZES.MEDIUM}px;
    }

    @media (min-width: ${MEDIA_SIZES.LARGE}px) {
      font-size: ${FONT_SIZES.LARGE}px;
    }

    @media (min-width: ${MEDIA_SIZES.EXTRA_LARGE}px) {
      font-size: ${FONT_SIZES.EXTRA_LARGE}px;
    }
  }
`

const StyledContainer = styled("main")`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
`

const Layout: React.FC = ({ children }) => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    useFontLoaded("Roboto", () => setLoaded(true))
  }, [])

  return (
    <>
      <Global styles={globalStyles} />
      <ThemeProvider theme={DEFAULT_THEME}>
        <StyledContainer>
          <SEO title="Agrita Garnizone" />
          {typeof document !== "undefined" && <Background />}
          {loaded && children}
        </StyledContainer>
      </ThemeProvider>
    </>
  )
}

export default Layout
